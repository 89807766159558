/* eslint-disable max-len */
import React, { useCallback, useEffect, useState } from 'react';
import { Card, Container, Image, Form, Button } from 'react-bootstrap';
import loginBackground from '../../../images/loginBackground.svg';
import AppContainer from '../../global/AppContainer';

import { auth, firestore, googleProvider, microsoftProvider } from '../../providers/FirebaseProvider/firebase';
import Microsoft from '../../../images/Microsoft.svg';
import Google from '../../../images/google.svg';
import { isLoadingSignal } from '../../libs/signals';
import validatePassword from '../../utils/validatePassword';
import { useAlert } from '../../providers/AlertProvider/AlertProvider';

const CreateUser = () => {
  const setAlert = useAlert();
  const [userData, setUserData] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const fetchUserData = useCallback(async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const beforeEmail = urlParams.get('email');
    const email = beforeEmail ? beforeEmail.replace(/ /g, '+') : '';
    if (email) {
      const fbUserRef = firestore.collection('users').doc(email);
      try {
        const userSnap = await fbUserRef.get();
        const userObj = userSnap.data();
        if (!userObj || userObj?.userData?.isActive) {
          window.location = '/login';
        }
        setUserData(userObj?.userData);
      } catch (error) {
        console.error('Oops somthing went wrong!', error);
        window.location = '/login';
      }
    } else {
      window.location = '/login';
    }
  });

  useEffect(() => {
    fetchUserData();
  }, []);

  const handleCreateUser = async () => {
    isLoadingSignal.value = true;
    // TODO verify if password has all requirements
    const result = await auth.createUserWithEmailAndPassword(userData?.email, password);
    if (result) {
      await firestore.collection('users').doc(userData?.email).update({
        userData: {
          ...userData,
          isActive: true,
          creationTime: new Date(),
          uid: result?.user?.uid,
          lastSignInTime: new Date(),
        },
      });
      const fbUserRef = firestore.collection('users').doc(userData?.email);
      const userSnap = await fbUserRef.get();
      const userObj = userSnap.data();
      if (userObj?.userData?.uid) {
        isLoadingSignal.value = false;
        window.location.href = '/dashboard';
      }
    }
  };

  const handleCreateGoogleUser = async () => {
    try {
      await auth.signInWithPopup(googleProvider).then(async (result) => {
        isLoadingSignal.value = true;
        const googleEmail = result?.user?.email;
        if (googleEmail) {
          const queryString = window.location.search;
          const urlParams = new URLSearchParams(queryString);
          const email = urlParams.get('email');
          if (email === googleEmail) {
            await firestore.collection('users').doc(googleEmail).get().then(async (res) => {
              if (!res.exists) {
                isLoadingSignal.value = false;
                setAlert({
                  message: 'This user doesnt exist',
                });
              } else {
                const userObj = res.data();
                await firestore.collection('users').doc(googleEmail).update({
                  userData: {
                    ...userObj?.userData,
                    isActive: true,
                    creationTime: new Date(),
                    uid: result?.user?.uid,
                    lastSignInTime: new Date(),
                  },
                });
                await firestore.collection('users').doc(googleEmail).get().then(async (user) => {
                  if (user) {
                    isLoadingSignal.value = false;
                    window.location.href = '/dashboard';
                  }
                });
              }
            });
          } else {
            isLoadingSignal.value = false;
            setAlert({
              message: 'This user doesnt exist',
            });
          }
        }
      });
    } catch (err) {
      isLoadingSignal.value = false;
      console.error(err);
    }
  };

  const handleCreateMicrosoftUser = async () => {
    try {
      await auth.signInWithPopup(microsoftProvider)
        .then(async (result) => {
          isLoadingSignal.value = true;
          const personalEmail = result.additionalUserInfo.profile.mail;
          const microsoftEmail = result.additionalUserInfo.profile.userPrincipalName.toLowerCase();
          if (microsoftEmail) {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const email = urlParams.get('email');
            if (personalEmail === email) {
              await firestore.collection('users').doc(email).get().then(async (res) => {
                if (!res.exists) {
                  isLoadingSignal.value = false;
                  setAlert({
                    message: 'This user doesnt exist',
                  });
                } else {
                  const userObj = res.data();
                  console.log(userObj);
                  // delete old email and add with new microsfot email
                  firestore.collection('users').doc(userObj?.userData?.email).delete();
                  await firestore.collection('users').doc(microsoftEmail).set({
                    userData: {
                      ...userObj?.userData,
                      email: microsoftEmail,
                      readableEmail: personalEmail,
                      isActive: true,
                      creationTime: new Date(),
                      uid: result?.user?.uid,
                      lastSignInTime: new Date(),
                    },
                  }).then(async () => {
                    const fbClientRef = firestore.collection('clients').doc(userObj?.userData?.clientId);
                    const clientSnap = await fbClientRef.get();
                    const clientObj = clientSnap.data();
                    const membersArray = clientObj?.clientData?.members;
                    console.log('before', membersArray, microsoftEmail);
                    const usersPositionInArray = membersArray.findIndex(
                      member => member.userObj.id === personalEmail,
                    );
                    membersArray.splice(usersPositionInArray, 1);
                    membersArray.push({
                      userObj: firestore.collection('users').doc(microsoftEmail),
                    });
                    console.log(membersArray);
                    fbClientRef.update({
                      clientData: {
                        name: clientObj?.clientData?.name,
                        members: membersArray,
                      },
                    });
                  });

                  // eslint-disable-next-line max-len
                  await firestore.collection('users').doc(microsoftEmail).get().then(async (user) => {
                    console.log('huh', user);
                    if (user) {
                      isLoadingSignal.value = false;
                      window.location.href = '/dashboard';
                    }
                  });
                }
              });
            } else {
              isLoadingSignal.value = false;
              setAlert({
                message: 'This user doesnt exist',
              });
            }
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } catch (err) {
      isLoadingSignal.value = false;
      console.error(err);
    }
  };

  return (
    <AppContainer>
      <Image src={loginBackground} alt="login Background" className="position-absolute bottom-0 end-0" />
      <Container className="vh-100 d-flex align-items-center justify-content-center ">
        <Card className="shadow-lg bg-body rounded py-xl-64 px-xl-112 py-lg-40 px-lg-64 py-md-24 px-md-40 w-50">
          <h3 className="fw-700 mb-0">
            Hello
            {' '}
            {userData?.name}
            ,
          </h3>
          <h3 className="fw-700">
            Create Your Password!
          </h3>
          <small>Password must be:</small>
          <small>
            <ul>
              <li className={`text-${(password.length > 6 && password.length < 32) ? 'success' : 'dark'}`}>
                Be 6-32 characters in length
              </li>
              <li className={`text-${(password.length && (!password.startsWith(' ') && !password.endsWith(' '))) ? 'success' : 'dark'}`}>
                Password should neither start nor end with a space
              </li>
              <li className={`text-${validatePassword(password) ? 'success' : 'dark'}`}>
                Include characters for the following three categories:
              </li>
              <ul className={`text-${validatePassword(password) ? 'success' : 'dark'}`}>
                <li>At least one uppercase alphabet characters (A-Z) exists</li>
                <li>At least one lowercase alphabet characters (a-z) exists</li>
                <li>At least one digit (0-9) exists</li>
              </ul>
            </ul>
          </small>
          <Form.Group className="form-group">
            <Form.Label htmlFor="inputEmail">Password</Form.Label>
            <Form.Control
              className="form-control form-control-sm mb-16"
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <Form.Label htmlFor="inputEmail">Confirm Password</Form.Label>
            <Form.Control
              className="form-control form-control-sm"
              type="password"
              name="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <Button
              className="d-flex w-100 justify-content-center fw-700 py-8 mt-16"
              type="submit"
              variant="dark-blue"
              disabled={
                password.length < 6 ||
                password.length > 32 ||
                password !== confirmPassword ||
                password.startsWith(' ') ||
                password.endsWith(' ') ||
                !validatePassword(password)
              }
              onClick={() => handleCreateUser()}
            >
              Continue
            </Button>
            <div className="border border-1 mx-40 my-16 border-primary" />
            <Form.Label htmlFor="inputEmail" className="d-flex w-100 justify-content-center">Or create user with</Form.Label>
            <Button
              className="d-flex w-100 justify-content-center fw-700 py-8 mt-16"
              type="submit"
              variant="light"
              onClick={() => handleCreateGoogleUser()}
            >
              <Image src={Google} className="me-8" style={{ width: '22px' }} />
              Google
            </Button>
            <Button
              className="d-flex w-100 justify-content-center fw-700 py-8 mt-16"
              type="submit"
              variant="light"
              onClick={() => handleCreateMicrosoftUser()}
            >
              <Image src={Microsoft} className="me-8" style={{ padding: '3px' }} />
              Microsoft
            </Button>
          </Form.Group>
        </Card>
      </Container>
    </AppContainer>
  );
};

export default CreateUser;
